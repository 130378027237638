import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Mary Salemme', // e.g: 'Name | Developer'
  lang: '', // e.g: en, es, fr, jp
  description: '', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hi, I am ',
  name: 'Mary Salemme',
  subtitle: "I'm an iOS Software Engineer",
  cta: 'Know more',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.png',
  paragraphOne:
    'I am an iOS Software Engineer formed at Makers coding bootcamp, currently working with Swift on the Meerkat app at Comparethemarket, with past experience in Web Development using Node.js and React.',
  paragraphTwo:
    'I’m a stickler for high quality code, I constantly ask for technical feedback from my peers and I very much enjoy pair programming.',
  paragraphThree:
    'In my spare time I’m learning to develop apps that keep accessibility at the forefront of my mind and I volunteer as Event Coordinator and Web Developer for Women Driven Development.',
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'hit-and-pun.png',
    title: 'Hit and Pun',
    info:
      'This is an app I made in one day in collaboration with my mentor and dear friend Andrew McGee.',
    info2:
      'It is powered by the icanhazdadjoke API, it uses MVVM-C architecture, Combine and a network layer I wrote in my spare time.',
    url: 'https://apps.apple.com/gb/app/hit-and-pun/id1555952767',
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: "If you'd like to get in touch",
  btn: 'email me',
  email: 'msalemmedev@icloud.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'medium',
      url: 'https://medium.com/@marysalemme',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/salemmemary/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/MarySalemme',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
